import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

import imgTickets from '../assets/img-tickets.webp'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { PageLayout } from '../styles/Layout'
import { LegalsStyled } from '../styles/LegalsStyled'
import { TitleS, BodyM } from '../styles/Typography'

const Awareness = () => {
  const theme = useTheme()

  const [isReverse, setIsReverse] = useState(false)

  const changeBackgroundColor = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const pageHeight = document.body.offsetHeight - (window.innerHeight + 300)

    if (windowScroll >= pageHeight) {
      setIsReverse(true)
    } else {
      setIsReverse(false)
    }
  }

  const onScroll = () => {
    window.requestAnimationFrame(changeBackgroundColor)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <SEO
        title="Butik | Awareness"
        description="Butik Electronic Music Festival"
        image={imgTickets}
      />
      <PageLayout
        backgroundColor={
          isReverse ? theme.backgroundDark : theme.backgroundPrimary
        }
      >
        <Header
          textColor={isReverse ? theme.backgroundPrimary : theme.backgroundDark}
        />
        <LegalsStyled>
          <div className="content">
            <div className="inner">
              <TitleS className="title">Awareness at Butik Festival</TitleS>
              <BodyM className="text">
                We want the Butik Festival to be a lovely experience for
                everyone, so we encourage attentive, respectful and conscious
                interactions.
              </BodyM>
              <BodyM className="text">
                We urge you to respect individual boundaries and act according
                to the principle of consent (only YES means YES). Any practice
                or threat of psychological or physical violence is strictly
                prohibited. Sexism, racism, ableism, homo- and transphobia and
                all other forms of discrimination will not be tolerated.
              </BodyM>
              <BodyM className="text">
                Butik Festival is supposed to be a place where everybody is
                welcome and accepted. We believe these simple principles are key
                for respectful and tolerant behavior of all attendees.
              </BodyM>
              <BodyM className="text">
                In the event of any form of violence and/or discrimination
                please immediately turn to the INFO POINT, and we will do our
                best to resolve your issue. Any breach of aforementioned rules
                and/or discriminatory behaviour will result in exclusion from
                the festival.
              </BodyM>
              <BodyM className="text">
                We follow a zero-tolerance policy in regards to drugs. The
                possession, dealing and/or public consumption of illegal drugs
                is prohibited on the entire festival site. Violation of this
                rule will result in exclusion from the festival.
              </BodyM>
            </div>
            <Footer isReverse={isReverse} />
          </div>
        </LegalsStyled>
      </PageLayout>
    </>
  )
}

export default Awareness
